<template>
  <div class="grey--text">
    {{ taskNumber }}
  </div>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    taskNumber() {
      return this.task["taskNumber"] || 0;
    }
  }
};
</script>
